import React from 'react';
import styled from "styled-components";
import HeaderImage from "../utils/Images/Header.png";
import Img1 from "../utils/Images/about1.jpg";
import Img2 from "../utils/Images/about2.jpg";
import Img3 from "../utils/Images/about3.jpg";
// import ProductCategoryCard from '../components/cards/ProductCategoryCard';
// import { categories } from '../utils/data';
import { products } from '../utils/products';
import ProductCard from '../components/cards/ProductCard';
import "../style.css"
import Footer from "../components/Footer"
import Review from "../components/Review"


const Container = styled.div`
  overflow-y: scroll;
  display: flex;
  align-items: center;
   flex-direction: column;
   gap: 30px;
  background: ${({ theme })=> theme.bg}
`;
const Section = styled.div`
  max-width: 1400px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
    align-items: center;
  font-size: 22px;
  gap: 28px;
`;
const Img = styled.img`
width: 90%;
height: 700px;
object-fit: cover;
max-width: 1200px;
// border: 1px solid grey;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.22);

padding: 10px;
@media (max-width: 768px){
  border-radius: 23px;
}
`;
const Title = styled.div`
font-size: 28px;
font-weight: 500;
display: flex;
justify-content: ${({center})=> (center ? "center" : "space-between")};
align-items: center;
`;
const CardWrapper=  styled.div`
display: flex;
flex-wrap: wrap;
gap: 24px;
justify-content: center;
@media (max-width: 750px){
gap: 14px;
}
`;


const Home = () => {
  return (
    <Container>
      <Section
        style={{
          alignItems: "center"
        }}>
        <Img src={HeaderImage}/>
      </Section>
      <div className="aboutSection" style={{color: "white"}}>
        <div className="leftSection">
          <img className='aboutImg1' src={Img1} alt="" />
          <img className='aboutImg2' src={Img2} alt="" />
          <img className='aboutImg3' src={Img3} alt="" />
        </div>
        <div className="rightSection">
          <h1 style={{textAlign: "start"}}>About</h1>
          <p>At YOUNG FIT, we believe that fashion is more than just clothing – it’s a statement of individuality, confidence, and empowerment. We’re passionate about curating high-quality, stylish collections that cater to the modern woman who wants to look and feel her best, no matter the occasion.</p>
        </div>
      </div>
      {/* <Section>
        <Title>Shop by Categories</Title>
        <CardWrapper>
          {categories.map((category)=>(
            <ProductCategoryCard category={category}/>
          ))}
        </CardWrapper>
      </Section> */}
      <Section>
        <Title center>Our Products</Title>
        <CardWrapper>
            {products.map((product)=>(

            <ProductCard key={product.id} product={product}/>
            ))}
            
          
        </CardWrapper>
      </Section>
      <Review/>
      <Footer/>
    </Container>
  )
}

export default Home;