import React, { useState } from 'react'
import { Modal } from "@mui/material";
import styled from "styled-components";
import LogoImg from "../utils/Images/logo.png"
import { Close } from '@mui/icons-material';
import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import AuthImage from "../utils/Images/Auth.jfif"
import { NavLink } from "react-router-dom";



const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  background: ${({ theme })=> theme.bg};
`;
const Left = styled.div`
flex: 1;
position: relative;
@media screen and (max-width: 768px){
  display: none;
}
`;
const NavLogo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 6px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: inherit;
`;
const Logo= styled.img`
  position: absolute;
  top: 9px;
  left: 30px;
  z-index: 10;
   width: 251px;
  @media screen and (max-width: 768px) {
    width: 160px;
  }
`;
const Image= styled.img`
  position: relative;
  height: 150%;
  width: 100%;
  obect-fit: cover;

`;

const Right = styled.div`
 position: relative;
 flex: 0.9;
 display: flex;
 flex-direction: column;
 padding: 40px;
 gap: 16px;
 align-items: center;
 justify-content: center;
@media screen and (max-width: 768px){
  flex: 1;
}
`;
 const CloseButton = styled.div`
   position: absolute;
   top: 20px;
   right: 20px;
   border-radius: 50%;
   padding: 2px;
   width: 32px;
   height: 32px;
   border: 1px solid ${({theme})=> theme.primary};
   display: flex;
   justify-content: center;
   align-items: center;
   &:hover{
   background: ${({ theme })=> theme.primary + 20};
   }
 `;

 const Text = styled.p`
    display: flex;
    gap: 12px;
    font-size: 16px;
    text-align: center;
    color: ${({theme})=> theme.text_secondary};
    margin-top: 16px;
    @media(max-width: 400px){
    font-size: 14px;
    }
 `;
 const TextButton = styled.div`
 color: ${({ theme })=> theme.primary};
 cursor: pointer;
 transition: all 0.3s ease;
 font-weight: 600;

 `;

const Authentication = ({openAuth, setOpenAuth}) => {
  const [login, setLogin]= useState(true);
  return (
<Modal open={openAuth} onClose={()=> setOpenAuth(false)}>
        <Container>
            <Left>
              <NavLogo>
             <NavLink to="/">
            <Logo src={LogoImg} />
          </NavLink>
          </NavLogo>
              <Image src= {AuthImage}/>
            </Left>
            <Right>
              <CloseButton>
                <Close onClick={()=> setOpenAuth(false)}/>
                </CloseButton>    
                {login ? (
                  <>
                  <SignIn/>
                  <Text>Don't have an account ?{" "}
                    <TextButton onClick={()=> setLogin(false)}>Sign Up</TextButton></Text>
                  </>
                  ) : (
                  <>
                  <SignUp/>
                  <Text>Already have an account ?{" "}
                    <TextButton onClick={()=> setLogin(true)}>Sign In</TextButton></Text>

                  </>
                  )}        
                </Right>
        </Container>
      
    </Modal>  )
}

export default Authentication