import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "./utils/Themes";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
// import Favourite from "./pages/Favourite";
// import NewArrival from "./pages/NewArrival";
import ShopListing from "./pages/ShopListing";
import About from "./pages/About";
import { useState } from "react";
import Authentication from "./pages/Authentication";
import ProductDetails from "./pages/ProductDetails";


const Container = styled.div`
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
background: ${({ theme })=>theme.bg};
color: ${({ theme })=> theme.text_primary};
`;


function App() {
  const [openAuth, setOpenAuth] = useState(false);
  return(
   <ThemeProvider theme={lightTheme}>
    <BrowserRouter>
      <Container>
        <Navbar setOpenAuth={setOpenAuth}/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/shop" element={<ShopListing/>}/>
          {/* <Route path="/favourite" element={<Favourite/>}/> */}
          {/* <Route path="/cart" element={<Cart/>}/> */}
          <Route path="/shopListing" element={<ShopListing/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/shop/:pid" element={<ProductDetails/>}/>
          <Route path="/shop/:img" element={<ProductDetails/>}/>

          {/* products */}
          {/* <Route path="/shop/6" element={<Product6/>}/> */}

        </Routes>
        {openAuth && <Authentication openAuth={openAuth} setOpenAuth={setOpenAuth}/>}
      </Container>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
