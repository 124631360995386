import React, { useState } from 'react'
import Footer from '../components/Footer'
import styled from 'styled-components';
import Button from '../components/Button';
// import TextInput from '../components/TextInput';
import Img from "../utils/Images/contact.jpg"

const Container = styled.div`

`;
const Section = styled.div`
display: flex;
gap: 20px;

justify-content: space-between;
padding: 16px 92px;
@media (max-width: 768px){
flex-direction: column;
gap: 20px;
padding: 16px 32px;
}

`;
const Section1 = styled.div`
width: 100%;
display: flex;
gap: 20px;
flex-direction: column;
align-items: center;
justify-content: center;
@media (max-width: 768px){
flex-direction: row;
// gap: 40px;

}
`;
const Image = styled.img`
width: 500px;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
  border-radius: 20px;
@media (max-width: 968px){
width: 350px;
}
@media (max-width: 868px){
width: 270px;
}
@media (max-width: 768px){
width: 250px;
}
@media (max-width: 529px){
width: 170px;
}
@media (max-width: 425px){
display: none;
}
`;
const Section2= styled.div`
width: 100%;
`;
const Title= styled.div`
font-size: 30px;
font-weight: 800;
color: ${({ theme })=> theme.primary};
`;
const Span= styled.div`
font-size: 16px;
font-weight: 400;
color: ${({ theme })=> theme.text_secondary + 90};
`;

const Contact = () => {
  const [name, setName]= useState("");
  const [email, setEmail]= useState("");
  const [msg, setMsg]= useState("");

  function getFormData(e){
    console.log(name,email,msg);
    e.preventDefault();
  }
  return (
      <Container>
        <Section>
        <Section1>
          <Image src={Img}/>
          <div>
            <div className='flex responsive'>
            <div className='flex align gap'>
              <span className="material-symbols-outlined round-border equal size">mail</span>
              <p className='size'>info@youngfitcn.com</p>
            </div>
            <div className='flex align gap'>
              <span className="material-symbols-outlined round-border equal size">call</span>
              <p className='size'>+86 186 8229 0483</p>
            </div>
            </div>
            <div className='flex align gap relative'>
              <span className="material-symbols-outlined round-border equal size absolute">home</span>
              <p className='size paddingLeft' style={{textAlign: "start"}}>No. 38, Industrial Avenue, Economic Development Zone, Dawu County, Hubei Province, China</p>
            </div>
          </div>
        </Section1>
        <Section2>
        <div>
          <Title>CONTACT US</Title>
          <Span>Ask ant questions?</Span>
        </div>
        <form onSubmit={getFormData}>
        <div style={{ display: "flex", gap: "20px", flexDirection: "column"}}>
          <div className='flex column'>
        <label for="name">Name</label>
        <input className='inputpad' type="text" name='name' placeholder='Enter your name' value={name} onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div className='flex column'>
        <label for="email">Email</label>
        <input className='inputpad' type="text" name='email' placeholder='Enter your email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </div>
        <div className='flex column'>
          <label for="msg">Your Message</label>
          <textarea className='inputpad' name="msg" id="msg" rows={6} placeholder='Your Message' value={msg} onChange={(e)=>setMsg(e.target.value)}></textarea>
        </div>
          <button type="submit">Send</button>
        </div>
          </form>
        </Section2>
        </Section>
        <Footer/>
      </Container>
  )
}

export default Contact
