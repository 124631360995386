import React from 'react'
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
// import {CircularProgress, Rating} from "@mui/material";
// import { AddShoppingCartOutlined, FavoriteRounded, FavoriteBorder} from "@mui/icons-material"
// import cardImage from "../../utils/Images/product1.jpg"



const Card = styled.div`
width: 250px;
display: flex;
flex-direction: column;
gap: 16px;
transition: all 0.3s ease-out;
cursor: pointer;
@media (max-width: 750px){
width : 180px;
}
`;
const Image = styled.img`
width: 100%;
height: 320px;
border-radius: 9px;
object-fit: cover;
transition: all 0.3s ease-out;
filter: blur(0.6px);
  box-shadow: 2px 3px 10px 2px rgba(0, 0, 0, 0.22);
@media (max-width: 750px){
height : 240px;
filter: blur(0px);

}
`;


const Menu = styled.div`
position: absolute;
z-index: 9;
color: ${({theme})=> theme.text_primary};
top: 14px;
right: 14px;
display: none;
flex-direction: column;
gap: 12px;
`;

const Top = styled.div`
display: flex;
align-items: center;
justify-content: center;
position: relative;
border-radius: 9px;
transition: all 0.3s ease-out;
&: hover{
background-color: ${({ theme })=> theme.primary};
}
&: hover ${Image}{
    opacity: 0.82;
filter: blur(0px);
}
&: hover ${Menu}{
display: flex
}
`;
// const MenuItem = styled.div`
//     border-radius: 50%;
//     width: 18px;
//     height: 18px;
//     background: white;
//     padding: 8px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 200;
// `;

// const Rate = styled.div`
//     position: absolute;
//     z-index: 9;
//     color: ${({theme})=> theme.text_primary};
//     bottom: 8px;
//     left: 78px;
//     padding: 4px 12px;
//     background: transparent();
//     display: flex;
//     align-items: center;
//     opacity: 0.9;
//     border-radius: 6px;
// `;

const Details = styled.div`
    display: flex;
    gap: 6px;
    flex-direction: column;
    padding: 4px 10px;
`;
const Title = styled.div`
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    color: ${({ theme })=> theme.text_primary};
    height: 45px;
    overflow: hidden;
    `;
// const Desc = styled.div`
//     font-size: 16px;
//     font-weight: 400;
//     color: ${({ theme})=> theme.text_primary};
//     overflow: hidden;
//     text-overflow: ;ellipsis;
//     white-space: nowrap;
//     text-align: center;


// `;
const Price = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    font-weight: 800;
    color: ${({ theme})=> theme.text_primary};
    // color: green;

`;
const Span = styled.div`
     font-size: 14px;
     font-weight: 500;
     color: ${({ theme })=> theme.text_secondary + 60};
     text-decoration: line-through;
     text-decoration-color: ${({ theme })=> theme.text_secondary + 50}
`;
// const Percent = styled.div`
//      font-size: 12px;
//      font-weight: 500;
//      color: green;
// `;

const ProductCard = ({product}) => {
    const navigate = useNavigate();
  return (
        <Card onClick={()=>navigate(`/shop/${product.id}`)}>
            <Top>
                <Image src={product.img}/>
                {/* <Menu>
                <MenuItem>
                    <FavoriteRounded sx={{ fontSize: "20px", color: "red" }}/>
                </MenuItem>{" "}
                    <MenuItem>
                        <AddShoppingCartOutlined sx={{ color: "inherit", fontSize: "20px"}}
                        // onClick={()=> addCart(product?._id )}
                        />
                    </MenuItem>
                </Menu> */}
                {/* <Rate>
                    <Rating value={3.5} sx={{ fontSize : "14px" }} />
                </Rate>  */}
            </Top>
            <Details>
                <Title>{product.name}</Title>
                {/* <Desc>{product.desc}</Desc> */}
                <Price>${product.price}
                    {/* <Span>$ {product.mrp}</Span> */}
                {/* <Percent>20%OFF</Percent> */}
                </Price>
            </Details>
        </Card>

)
}

export default ProductCard;
