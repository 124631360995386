import React, { useState } from 'react';
import styled from "styled-components";
import ProductCard from '../components/cards/ProductCard';
import Footer from "../components/Footer"
import { products } from "../utils/products";
import { categories } from "../utils/data";


const Container = styled.div`
// padding: 20px 30px;
  // height: 100%;
  display: flex;
  align-items: center;
      flex-direction: column;
   gap: 30px;
   @media ( max-width: 768px){
    padding: 0px 12px;
    flex-direction: column;
    }
  background: ${({ theme })=> theme.bg}
`;
const Section = styled.div`
max-width: 1400px;
padding: 32px 16px;
display: flex;
flex-direction: column;
  align-items: center;
font-size: 22px;
gap: 28px;
`;
  const CardWrapper= styled.div`
  display: flex;
  flex-wrap: wrap;
    padding: 12px;
    gap: 24px;
    justify-content: center;
    @media (max-width: 768px){
    gap: 14px;
    }
    height: 100%;
   
  `;
  const Title = styled.div`
  font-size: 28px;
  font-weight: 500;
  display: flex;
  justify-content: ${({center})=> (center ? "center" : "space-between")};
  align-items: center;
  `;

const ShopListing = () => {
  
  return (
    <Container>
      {/* <Section>
        <Title>Shop by Categories</Title>
        <CardWrapper>

        {categories.map((product)=>(

<ProductCard product={product}/>
))}
        </CardWrapper>
        </Section> */}
        <Section>

        <Title center>Our Products</Title>
        <CardWrapper>

        {products.map((product)=>(

<ProductCard key={product.id} product={product}/>
))}
        </CardWrapper>
        </Section>
      <Footer/>
    </Container>
  )
}

export default ShopListing;